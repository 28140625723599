
import { useNavigate } from "react-router";
import "./styles.css";
import { getUrlFromEventTitle } from "../../utilities/helpers";

const EventCard = ({ index, event }) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`../${getUrlFromEventTitle(event.title)}`);
  };

  return (
    <div key={index} className="event" onClick={() => handleOnClick()}>
      <div className="eventHeader">
        <img src={event.image} alt={event.title} />
        <div className="date">
          <div>
            <h2>{event.date.day}</h2>
            <span>{event.date.month} {event.date.year}</span>
          </div>
        </div>
      </div>
      <div className="eventContent">
        <span>{event.type}</span>
        <h2>{event.title}</h2>
        <p>{event.description}</p>
        <span>{event.eventRanBy}</span>
      </div>
    </div>
  );
};

export default EventCard;