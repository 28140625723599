const PrivacyPolicy = () => (
  <div style={{ textAlign: "left", paddingLeft: '32px' }}>
    <span style={{ fontSize: "26px" }}>
      <span data-custom-class="title">
        <bdt class="block-component"></bdt>
        <bdt class="question">PRIVACY POLICY</bdt>
        <bdt class="statement-end-if-in-editor"></bdt>
      </span>
    </span>
    <span style={{ color: "rgb(127, 127, 127)"}}>
      <strong>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="subtitle">Last updated <bdt class="question">March 31, 2024</bdt>
          </span>
        </span>
      </strong>
    </span>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)"}}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
          <span data-custom-class="body_text">This privacy notice for <bdt class="question">Safer West <bdt class="block-component"></bdt>
          </bdt> ( <bdt class="block-component"></bdt>' <strong>we</strong>', ' <strong>us</strong>', or ' <strong>our</strong>' <bdt class="else-block"></bdt>
          </span>
          <span data-custom-class="body_text">), describes how and why we might collect, store, use, and/or share ( <bdt class="block-component"></bdt>' <strong>process</strong>' <bdt class="else-block"></bdt>) your information when you use our services ( <bdt class="block-component"></bdt>' <strong>Services</strong>' <bdt class="else-block"></bdt>), such as when you: </span>
        </span>
      </span>
      <span style={{ fontSize: "15px" }}>
        <span style={{ color: "rgb(127, 127, 127)"}}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)"}}>
              <span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </span>
      </span>
      <div>
        <bdt class="block-component">
          <span style={{ fontSize: "15px" }}>
            <span style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(127, 127, 127)"}}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)"}}>
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </bdt>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">Download and use <bdt class="block-component"></bdt> our mobile application <bdt class="block-component"></bdt> ( <bdt class="question">Safer West) <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)"}}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)"}}>
                              <bdt class="statement-end-if-in-editor">,</bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              </bdt>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)"}}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)"}}>
                          <bdt class="statement-end-if-in-editor">
                            <bdt class="block-component"> or any other application of ours that links to this privacy notice</bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)"}}>
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or events <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)"}}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)"}}>
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span data-custom-class="body_text">
              <strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. <bdt class="block-component"></bdt> If you still have any questions or concerns, please contact us at <bdt class="question">ciaran@codebooth.uk</bdt>. </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <strong>
          <span style={{ fontSize: "15px" }}>
            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>
              <em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</em>
            </strong>
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">
              <strong>
                <em>table of contents</em>
              </strong>
            </span>
          </span>
        </a>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>
              <em>&nbsp;below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about&nbsp; </span>
        </span>
        <a data-custom-class="link" href="#personalinfo">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">personal information you disclose to us</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>Do we process any sensitive personal information?</strong>
            <bdt class="block-component"></bdt>We do not process sensitive personal information. <bdt class="else-block"></bdt>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>Do we receive any information from third parties?</strong>
            <bdt class="block-component"></bdt>We do not receive any information from third parties. <bdt class="else-block"></bdt>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp; </span>
        </span>
        <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">how we process your information</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>In what situations and with which <bdt class="block-component"></bdt>parties do we share personal information? </strong> We may share information in specific situations and with specific <bdt class="block-component"></bdt>third parties. Learn more about&nbsp; </span>
        </span>
        <a data-custom-class="link" href="#whoshare">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">when and with whom we share your personal information</span>
          </span>
        </a>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">. <bdt class="block-component"></bdt>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have <bdt class="block-component"></bdt>organisational <bdt class="else-block"></bdt> and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt class="block-component"></bdt>unauthorised <bdt class="else-block"></bdt> third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp; </span>
        </span>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">how we keep your information safe</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <bdt class="statement-end-if-in-editor"></bdt>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp; </span>
        </span>
        <a data-custom-class="link" href="#privacyrights">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">your privacy rights</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">Want to learn more about what we do with any information we collect?&nbsp;</span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
            <span data-custom-class="body_text">Review the privacy notice in full</span>
          </span>
        </a>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">.</span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#infocollect">
            <span style={{ color: "rgb(0, 58, 250)" }}>1. WHAT INFORMATION DO WE COLLECT?</span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)" }}>2. HOW DO WE PROCESS YOUR INFORMATION? <bdt class="block-component"></bdt>
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#legalbases">
            <span style={{ color: "rgb(0, 58, 250)" }}>3. <span style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 58, 250)" }}>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</span>
            </span>
              <bdt class="statement-end-if-in-editor"></bdt>
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
          </span>
          <span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
            <span style={{ color: "rgb(127, 127, 127)"}}>
              <span style={{ color: "rgb(89, 89, 89)"}}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)"}}>
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#cookies">
            <span style={{ color: "rgb(0, 58, 250)" }}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span style={{ color: "rgb(89, 89, 89)"}}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)"}}>
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)"}}>
                  <span style={{ color: "rgb(89, 89, 89)"}}>
                    <span style={{ color: "rgb(89, 89, 89)"}}>
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#inforetain">
            <span style={{ color: "rgb(0, 58, 250)" }}>6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span style={{ color: "rgb(89, 89, 89)"}}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)"}}>
                  <span style={{ color: "rgb(89, 89, 89)"}}>
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(0, 58, 250)" }}>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span style={{ color: "rgb(89, 89, 89)"}}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)"}}>
                  <bdt class="statement-end-if-in-editor"></bdt>
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#privacyrights">8. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <a data-custom-class="link" href="#DNT">
            <span style={{ color: "rgb(0, 58, 250)" }}>9. CONTROLS FOR DO-NOT-TRACK FEATURES <bdt class="block-component"></bdt>
            </span>
            <bdt class="block-component">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text"></span>
              </span>
            </bdt>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#DNT">
          <bdt class="block-component">
            <span style={{ fontSize: "15px" }}></span>
          </bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
          <bdt class="block-component"></bdt>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#DNT">
          <span style={{ fontSize: "15px" }}></span>
        </a>
        <a data-custom-class="link" href="#policyupdates">
          <span style={{ color: "rgb(0, 58, 250)" }}>10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#request">
          <span style={{ color: "rgb(0, 58, 250)" }}>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="infocollect" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "15px" }}>
            <span style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}>
              <span style={{ fontSize: "15px", color: "rgb(0, 0, 0)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="personalinfo" style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: "15px" }}>
            <strong>Personal information you disclose to us</strong>
          </span>
        </span>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)"}}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>We collect personal information that you provide to us.</em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you <span style={{ fontSize: "15px" }}>
              <bdt class="block-component"></bdt>
            </span>
            </span>
            <span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: "15px" }}>
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>
            <bdt class="block-component"></bdt>We do not process sensitive information.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
          <span data-custom-class="body_text">
            <bdt class="else-block"></bdt>
          </span>
        </span>
        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt class="block-component">
                    <bdt class="block-component"></bdt>
                  </bdt>
                </span>
              </span>
            </span>
          </span>
          <bdt class="block-component">
            <bdt class="block-component"></bdt>
          </bdt>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          <span style={{ fontSize: "15px" }}>
            <strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission: <bdt class="block-component"></bdt>
          </span>
        </span>
        <div style={{ lineHeight: "1.5" }}>
          <bdt class="block-component">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text"></span>
            </span>
          </bdt>
          <div style={{ lineHeight: "1.5" }}>
            <bdt class="block-component">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text"></span>
              </span>
            </bdt>
            <div style={{ lineHeight: "1.5" }}>
              <bdt class="block-component">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text"></span>
                </span>
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings. <bdt class="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <bdt class="statement-end-if-in-editor">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text"></span>
                </span>
              </bdt>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                <span style={{ fontSize: "15px" }}>
                  <strong>Information automatically collected</strong>
                </span>
              </span>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)"}}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:</em>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>
                              <em>&nbsp;</em>
                            </strong>
                            <em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies. <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt class="statement-end-if-in-editor">
                      <span data-custom-class="body_text"></span>
                    </bdt>
                  </span>
                  <span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">The information we collect includes: <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services <span style={{ fontSize: "15px" }}>&nbsp;</span>(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called <bdt class="block-component"></bdt>'crash dumps' <bdt class="else-block"></bdt>), and hardware settings). <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)"}}>
                                <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt class="block-component">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text"></span>
                </span>
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information. <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)"}}>
                                <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt class="block-component">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text"></span>
                </span>
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services. <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)"}}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)"}}>
                                <bdt class="statement-end-if-in-editor"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt class="block-component">
                <span style={{ fontSize: "15px" }}></span>
              </bdt>
              <bdt class="statement-end-if-in-editor"></bdt>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt class="statement-end-if-in-editor">
                          <bdt class="block-component"></bdt>
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="infouse" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)"}}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)"}}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                            <em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt class="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt class="block-component">
                          <span style={{ fontSize: "15px" }}></span>
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt class="block-component">
                            <span style={{ fontSize: "15px" }}></span>
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt class="block-component">
                              <span style={{ fontSize: "15px" }}></span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt class="block-component">
                                <span style={{ fontSize: "15px" }}>
                                  <span data-custom-class="body_text"></span>
                                </span>
                              </bdt>
                              <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                                <bdt class="block-component">
                                  <span style={{ fontSize: "15px" }}></span>
                                </bdt>
                              </p>
                              <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                                <bdt class="block-component">
                                  <span style={{ fontSize: "15px" }}></span>
                                </bdt>
                              </p>
                              <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                                <bdt class="block-component"></bdt>
                              </p>
                              <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                                <bdt class="block-component"></bdt>
                              </p>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt class="block-component">
                                  <span style={{ fontSize: "15px" }}>
                                    <span data-custom-class="body_text"></span>
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt class="block-component">
                                    <span style={{ fontSize: "15px" }}></span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt class="block-component">
                                      <span style={{ fontSize: "15px" }}></span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <bdt class="block-component">
                                          <span data-custom-class="body_text"></span>
                                        </bdt>
                                      </span>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt class="block-component">
                                          <span style={{ fontSize: "15px" }}>
                                            <span data-custom-class="body_text"></span>
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt class="block-component">
                                            <span style={{ fontSize: "15px" }}>
                                              <span data-custom-class="body_text"></span>
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt class="block-component">
                                              <span style={{ fontSize: "15px" }}>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt class="block-component">
                                                <span style={{ fontSize: "15px" }}>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                              <div style={{ lineHeight: "1.5" }}>
                                                <bdt class="block-component">
                                                  <span style={{ fontSize: "15px" }}>
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                  <bdt class="block-component">
                                                    <span style={{ fontSize: "15px" }}>
                                                      <span data-custom-class="body_text"></span>
                                                    </span>
                                                  </bdt>
                                                  <div style={{ lineHeight: "1.5" }}>
                                                    <bdt class="block-component">
                                                      <span style={{ fontSize: "15px" }}>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                      <bdt class="block-component">
                                                        <span style={{ fontSize: "15px" }}>
                                                          <span data-custom-class="body_text"></span>
                                                        </span>
                                                      </bdt>
                                                      <div style={{ lineHeight: "1.5" }}>
                                                        <bdt class="block-component">
                                                          <span style={{ fontSize: "15px" }}>
                                                            <span data-custom-class="body_text"></span>
                                                          </span>
                                                        </bdt>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                          <bdt class="block-component">
                                                            <span style={{ fontSize: "15px" }}>
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </bdt>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <bdt class="block-component">
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li style={{ lineHeight: "1.5" }}>
                                                              <span data-custom-class="body_text">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm. </span>
                                                              </span>
                                                              <bdt class="statement-end-if-in-editor">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <bdt class="block-component">
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                            <bdt class="block-component">
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                            <bdt class="block-component">
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                            <bdt class="block-component">
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <br />
                                                          </div>
                                                          <div id="legalbases" style={{ lineHeight: "1.5" }}>
                                                            <strong>
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span>
                                                              </span>
                                                            </strong>
                                                          </div>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <br />
                                                          </div>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <em>
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text">
                                                                  <strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. <bdt class="block-component"></bdt> legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or <bdt class="block-component"></bdt>fulfil <bdt class="else-block"></bdt> our contractual obligations, to protect your rights, or to <bdt class="block-component"></bdt>fulfil <bdt class="else-block"></bdt> our legitimate business interests. </span>
                                                              </span>
                                                            </em>
                                                          </div>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <br />
                                                          </div>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <span style={{ fontSize: "15px" }}>
                                                              <span data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <span style={{ fontSize: "15px" }}>
                                                              <span data-custom-class="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li style={{ lineHeight: "1.5" }}>
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text">
                                                                  <strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e. <bdt class="block-component"></bdt> consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about&nbsp; </span>
                                                              </span>
                                                              <a data-custom-class="link" href="#withdrawconsent">
                                                                <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text">withdrawing your consent</span>
                                                                </span>
                                                              </a>
                                                              <span data-custom-class="body_text">.</span>
                                                            </li>
                                                          </ul>
                                                          <div style={{ lineHeight: "1.5" }}>
                                                            <bdt class="block-component">
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </bdt>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <bdt class="block-component">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                              <bdt class="block-component">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <ul>
                                                              <li style={{ lineHeight: "1.5" }}>
                                                                <span data-custom-class="body_text">
                                                                  <span style={{ fontSize: "15px" }}>
                                                                    <strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved. <bdt class="statement-end-if-in-editor"></bdt>
                                                                    <br />
                                                                  </span>
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <bdt class="block-component">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <ul>
                                                              <li style={{ lineHeight: "1.5" }}>
                                                                <span data-custom-class="body_text">
                                                                  <span style={{ fontSize: "15px" }}>
                                                                    <strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person. </span>
                                                                </span>
                                                                <bdt class="statement-end-if-in-editor">
                                                                  <span style={{ fontSize: "15px" }}>
                                                                    <span data-custom-class="body_text"></span>
                                                                  </span>
                                                                </bdt>
                                                              </li>
                                                            </ul>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <bdt class="block-component">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                              <span data-custom-class="body_text">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <bdt class="block-component">
                                                                    <bdt class="block-component"></bdt>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                              <bdt class="statement-end-if-in-editor">
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <br />
                                                            </div>
                                                            <div id="whoshare" style={{ lineHeight: "1.5" }}>
                                                              <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                    <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                        <strong>
                                                                          <span data-custom-class="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <br />
                                                            </div>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      <em>In Short:</em>
                                                                    </strong>
                                                                    <em>&nbsp;We may share information in specific situations described in this section and/or with the following <bdt class="block-component"></bdt>third parties. </em>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                  <span data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <br />
                                                            </div>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <span style={{ fontSize: "15px" }}>
                                                                <span data-custom-class="body_text">We <bdt class="block-component"></bdt>may need to share your personal information in the following situations: </span>
                                                              </span>
                                                            </div>
                                                            <ul>
                                                              <li style={{ lineHeight: "1.5" }}>
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text">
                                                                    <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </span>
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                              <bdt class="block-component">
                                                                <span style={{ fontSize: "15px" }}></span>
                                                              </bdt>
                                                              <div style={{ lineHeight: "1.5" }}>
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <span data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li style={{ lineHeight: "1.5" }}>
                                                                  <span style={{ fontSize: "15px" }}>
                                                                    <span data-custom-class="body_text">
                                                                      <strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform APIs (e.g. <bdt class="block-component"></bdt> Google Maps API, Places API). </span>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt class="block-component"></bdt>
                                                                      <bdt class="block-component"></bdt>
                                                                      <bdt class="block-component"></bdt>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div style={{ lineHeight: "1.5" }}>
                                                                <span style={{ fontSize: "15px" }}>
                                                                  <bdt class="block-component">
                                                                    <span data-custom-class="body_text"></span>
                                                                  </bdt>
                                                                </span>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                  <bdt class="block-component">
                                                                    <span style={{ fontSize: "15px" }}>
                                                                      <span data-custom-class="body_text"></span>
                                                                    </span>
                                                                  </bdt>
                                                                  <div style={{ lineHeight: "1.5" }}>
                                                                    <bdt class="block-component">
                                                                      <span style={{ fontSize: "15px" }}>
                                                                        <span data-custom-class="body_text"></span>
                                                                      </span>
                                                                    </bdt>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                      <bdt class="block-component">
                                                                        <span style={{ fontSize: "15px" }}>
                                                                          <span data-custom-class="body_text"></span>
                                                                        </span>
                                                                      </bdt>
                                                                      <span data-custom-class="body_text">
                                                                        <span style={{ fontSize: "15px" }}>
                                                                          <bdt class="block-component"></bdt>
                                                                        </span>
                                                                      </span>
                                                                      <bdt class="statement-end-if-in-editor">
                                                                        <span style={{ fontSize: "15px" }}>
                                                                          <span data-custom-class="body_text"></span>
                                                                        </span>
                                                                      </bdt>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt class="block-component">
                                                                          <span style={{ fontSize: "15px" }}>
                                                                            <span data-custom-class="body_text"></span>
                                                                          </span>
                                                                        </bdt>
                                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                          <span style={{ fontSize: "15px" }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                              <span style={{ fontSize: "15px" }}>
                                                                                <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                  <bdt class="block-component">
                                                                                    <span data-custom-class="heading_1"></span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="cookies" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                                  <strong>
                                                                                    <span data-custom-class="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
                                                                                  </strong>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <strong>
                                                                                <em>In Short:</em>
                                                                              </strong>
                                                                              <em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>.
                                                                              </span>
                                                                              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                                <span style={{ fontSize: "15px" }}>
                                                                                  <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                    <span style={{ fontSize: "15px" }}>
                                                                                      <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt class="statement-end-if-in-editor"></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                                    <span style={{ fontSize: "15px" }}>
                                                                                      <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                        <span style={{ fontSize: "15px" }}>
                                                                                          <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt class="block-component"></bdt>
                                                                                            </span>
                                                                                            <bdt class="block-component">
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt class="block-component"></bdt>
                                                                                              </span>
                                                                                            </bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="inforetain" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                                  <strong>
                                                                                    <span data-custom-class="heading_1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                                                                                  </strong>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <strong>
                                                                                <em>In Short:&nbsp;</em>
                                                                              </strong>
                                                                              <em>We keep your information for as long as necessary to <bdt class="block-component"></bdt>fulfil <bdt class="else-block"></bdt> the purposes outlined in this privacy notice unless otherwise required by law. </em>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). <bdt class="block-component"></bdt>
                                                                            </span>&nbsp; </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or <bdt class="block-component"></bdt>anonymise <bdt class="else-block"></bdt> such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                              <bdt class="block-component"></bdt>
                                                                            </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="infosafe" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                                  <strong>
                                                                                    <span data-custom-class="heading_1">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                                                                                  </strong>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <strong>
                                                                                <em>In Short:&nbsp;</em>
                                                                              </strong>
                                                                              <em>We aim to protect your personal information through a system of <bdt class="block-component"></bdt>organisational <bdt class="else-block"></bdt> and technical security measures. </em>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">We have implemented appropriate and reasonable technical and <bdt class="block-component"></bdt>organisational <bdt class="else-block"></bdt> security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt class="block-component"></bdt>unauthorised <bdt class="else-block"></bdt> third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment. <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                              <bdt class="statement-end-if-in-editor"></bdt>
                                                                            </span>
                                                                              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt class="block-component"></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="privacyrights" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                                  <strong>
                                                                                    <span data-custom-class="heading_1">8. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                                                                                  </strong>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <strong>
                                                                                <em>In Short:</em>
                                                                              </strong>
                                                                              <em>&nbsp; <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                <span style={{ fontSize: "15px" }}>
                                                                                  <span data-custom-class="body_text">
                                                                                    <em>
                                                                                      <bdt class="block-component"></bdt>
                                                                                    </em>
                                                                                  </span>
                                                                                </span>
                                                                              </span>In some regions, such as <bdt class="block-component"></bdt>the European Economic Area (EEA), United Kingdom (UK), and Switzerland <bdt class="block-component"></bdt>, you have rights that allow you greater access to and control over your personal information. <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                  <span style={{ fontSize: "15px" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <em>
                                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                                      </em>
                                                                                    </span>
                                                                                  </span>&nbsp; </span>You may review, change, or terminate your account at any time. </em>
                                                                              <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                <span style={{ fontSize: "15px" }}>
                                                                                  <bdt class="block-component"></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">In some regions (like <bdt class="block-component"></bdt>the EEA, UK, and Switzerland <bdt class="block-component"></bdt>), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <bdt class="block-component"></bdt>' <bdt class="else-block"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <a data-custom-class="link" href="#contact">
                                                                          <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                                                                              <span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                                                                            </span>
                                                                          </span>
                                                                        </a>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt class="block-component"></bdt>' <bdt class="else-block"></bdt> below.
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">We will consider and act upon any request in accordance with applicable data protection laws. <bdt class="block-component"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>&nbsp;</span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <span style={{ fontSize: "15px" }}>
                                                                              <span style={{ color: "rgb(0, 58, 250)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span style={{ color: "rgb(0, 58, 250)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <a data-custom-class="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank">
                                                                                        <span style={{ fontSize: "15px" }}>Member State data protection authority</span>
                                                                                      </a>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span> or&nbsp; </span>
                                                                          </span>
                                                                        </span>
                                                                        <a data-custom-class="link" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" rel="noopener noreferrer" target="_blank">
                                                                          <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                                                                              <span data-custom-class="body_text">UK data protection authority</span>
                                                                            </span>
                                                                          </span>
                                                                        </a>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">.</span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">If you are located in Switzerland, you may contact the <span style={{ fontSize: "15px" }}>
                                                                              <span style={{ color: "rgb(0, 58, 250)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span style={{ color: "rgb(0, 58, 250)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <span style={{ color: "rgb(0, 58, 250)", fontSize: "15px" }}>
                                                                                        <a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">Federal Data Protection and Information Commissioner</a>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>. </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="withdrawconsent" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <strong>
                                                                                <u>Withdrawing your consent:</u>
                                                                              </strong> If we are relying on your consent to process your personal information, <bdt class="block-component"></bdt>
                                                                              <bdt class="else-block"></bdt> you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <bdt class="block-component"></bdt>' <bdt class="else-block"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <a data-custom-class="link" href="#contact">
                                                                          <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(0, 58, 250)" }}>
                                                                              <span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                                                                            </span>
                                                                          </span>
                                                                        </a>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt class="block-component"></bdt>' <bdt class="else-block"></bdt> below <bdt class="block-component"></bdt>.
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px" }}>
                                                                          <span data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, <bdt class="block-component"></bdt>
                                                                            <bdt class="else-block"></bdt> will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. <bdt class="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                        <bdt class="block-component">
                                                                          <span style={{ fontSize: "15px" }}>
                                                                            <span data-custom-class="body_text"></span>
                                                                          </span>
                                                                        </bdt>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ fontSize: "15px" }}>
                                                                                <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                  <span style={{ fontSize: "15px" }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)"}}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span style={{ fontSize: "15px" }}>
                                                                                          <span style={{ color: "rgb(89, 89, 89)"}}><bdt class="block-component"></bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt class="block-component">
                                                                          <span style={{ fontSize: "15px" }}>
                                                                            <span data-custom-class="body_text"></span>
                                                                          </span>
                                                                        </bdt>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span data-custom-class="body_text">
                                                                          <span style={{ fontSize: "15px" }}>If you have questions or comments about your privacy rights, you may email us at <bdt class="question">ciaran@codebooth.uk</bdt>. </span>
                                                                        </span>
                                                                        <bdt class="statement-end-if-in-editor">
                                                                          <span style={{ fontSize: "15px" }}>
                                                                            <span data-custom-class="body_text"></span>
                                                                          </span>
                                                                        </bdt>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="DNT" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                                  <strong>
                                                                                    <span data-custom-class="heading_1">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                                                                                  </strong>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ( <bdt class="block-component"></bdt>'DNT' <bdt class="else-block"></bdt>) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for <bdt class="block-component"></bdt>recognising <bdt class="else-block"></bdt> and implementing DNT signals has been <bdt class="block-component"></bdt>finalised <bdt class="else-block"></bdt>. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. <bdt class="block-component"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt class="block-component">
                                                                          <span style={{ fontSize: "15px" }}></span>
                                                                        </bdt>
                                                                        <bdt class="block-component">
                                                                          <span style={{ fontSize: "15px" }}></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="policyupdates" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                                  <strong><span data-custom-class="heading_1">10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                                                                                  </strong>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                              <em>
                                                                                <strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws. </em>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated <bdt class="block-component"></bdt>'Revised' <bdt class="else-block"></bdt> date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information. </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="contact" style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)"}}>
                                                                          <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                                            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span id="control" style={{ color: "rgb(0, 0, 0)" }}><strong><span data-custom-class="heading_1">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></span></span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span data-custom-class="body_text">If you have questions or comments about this notice, you may <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}><span data-custom-class="body_text"><bdt class="block-component"><bdt class="block-component"></bdt></bdt>email us at <bdt class="question">ciaran@codebooth.uk or&nbsp;</bdt><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span data-custom-class="body_text">contact us by post at:</span></span></span></span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span data-custom-class="body_text"><span style={{ fontSize: "15px" }}><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ color: "rgb(89, 89, 89)"}}><span data-custom-class="body_text"><bdt class="question">Safer West</bdt></span></span></span></span></span><span data-custom-class="body_text"><span style={{ color: "rgb(89, 89, 89)"}}><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span></span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px" }}><span data-custom-class="body_text"><bdt class="question">__________</bdt><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ fontSize: "15px" }}><bdt class="block-component"></bdt></span></span></span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span data-custom-class="body_text"><span style={{ fontSize: "15px" }}><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ color: "rgb(89, 89, 89)"}}><span data-custom-class="body_text"><bdt class="question">Code Booth LTD</bdt></span></span></span></span></span><span data-custom-class="body_text"><span style={{ color: "rgb(89, 89, 89)"}}><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span></span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px" }}><span data-custom-class="body_text"><bdt class="question">__________</bdt><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ fontSize: "15px" }}><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt></span></span><span data-custom-class="body_text"><span style={{ fontSize: "15px" }}><bdt class="block-component"></bdt></span></span></span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span data-custom-class="body_text"><span style={{ fontSize: "15px" }}><bdt class="question">United Kingdom</bdt><bdt class="else-block"></bdt></span>
                                                                        </span>
                                                                        <span data-custom-class="body_text" style={{ fontSize: "15px" }}><bdt class="question"><span data-custom-class="body_text"><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ fontSize: "15px" }}><bdt class="else-block"></bdt></span></span></span></bdt><bdt class="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                        <span data-custom-class="body_text"><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ fontSize: "15px" }}><bdt class="statement-end-if-in-editor"></bdt></span></span>
                                                                        </span>
                                                                        <span data-custom-class="body_text"><span style={{ fontSize: "15px" }}><span data-custom-class="body_text"><span style={{ color: "rgb(89, 89, 89)"}}><bdt class="statement-end-if-in-editor"><span style={{ color: "rgb(89, 89, 89)"}}><span style={{ fontSize: "15px" }}><span data-custom-class="body_text"><bdt class="block-component"><bdt class="block-component"></bdt></bdt></span></span></span></bdt></span></span></span><bdt class="block-component"><span style={{ fontSize: "15px" }}></span></bdt><span style={{ fontSize: "15px" }}><span data-custom-class="body_text"><span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}><span style={{ fontSize: "15px" }}><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span></span></span></span>
                                                                        </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div id="request" style={{ lineHeight: "1.5" }}><span style={{ color: "rgb(127, 127, 127)"}}><span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}><span id="control" style={{ color: "rgb(0, 0, 0)" }}><strong><span data-custom-class="heading_1">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></span></span></span></span></span>
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <br />
                                                                      </div>
                                                                      <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                          <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please get in contact with ciaran@codebooth.uk and we will review your request.</span>
                                                                          </span>
                                                                          <span data-custom-class="body_text">.</span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;