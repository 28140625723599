import React from 'react';

import './App.css'; // Make sure to create an App.css file with the content provided below.
import Navbar from './components/nav-bar';
import Hero from './components/hero';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import EventDetails from './containers/event-details';
import { eventData } from './containers/event-carousel/eventData';
import { getUrlFromEventTitle, getUrlFromOrgName } from './utilities/helpers';
import EventCarousel from './containers/event-carousel';
import EventsList from './containers/events-list';
import { orgData } from './orgData';
import OrgDetails from './containers/org-details';
import ContactUs from './containers/contact-us';
import PrivacyPolicy from './containers/privacy-policy';
import Footer from './containers/footer';

const App = () => {
  return (
    <div className="app">
        <Navbar />
      <div className="content">
        <Router>
          <Routes>
            <Route path="/" element={
              <>
                <Hero image="url('/assets/main3.jpg')" title={<>We <span>support</span> the Community.</>} isDisplayTitle buttonText="Explore Events" buttonUrl="/all-events" />
                <EventCarousel title="Upcoming Events" />
                <Hero image="url('/assets/main-2.jpg')" title={<>Get <span>in touch</span> to find out more.</>} isDisplayTitle buttonText="Contact" buttonUrl="/contact" alignment="right" />
                <EventCarousel title="Community Events" filteredData={eventData.filter(event => event.type.includes("Outreach"))} />
                <EventCarousel title="Safety Awareness Events" filteredData={eventData.filter(event => event.type.includes("Community info/safety"))} />
              </>
            } />
            {eventData.map((event, i) => {
              return (
                <Route key={i} path={getUrlFromEventTitle(event.title)} element={
                  <EventDetails event={event} />
                } />
              );
            })}
            {orgData.map((org, i) => {
              return (
                <Route key={i} path={getUrlFromOrgName(org.title)} element={
                  <OrgDetails org={org} />
                } />
              );
            })}
            <Route path="/all-events" element={
              <EventsList events={eventData} />
            } />
            <Route path="/contact" element={
              <ContactUs />
            } />
            <Route path="/privacy-policy" element={
              <PrivacyPolicy />
            } />
          </Routes>
        </Router>
        <Footer />
      </div>
    </div>
  );
}

export default App;