import { isMobile } from "react-device-detect";
import hamburger from "../../hamburger.svg";
import logo from "../../assets/website-logo.svg";
import "./styles.css";
import { useState } from "react";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const options = (
    <>
      <a href="/" className="nav-item">Home</a>
      <a href="/all-events" className="nav-item">Events</a>
      <a href="/contact" className="nav-item contact">Contact</a>
    </>
  )

  return (
    <>
      {isMobile ? (
        <>
          <header className="header">
            <div className="logo-container">
              <a href="/"><img src={logo} alt="Safer West Logo" className="logo" /></a>
            </div>
            <>
              <img src={hamburger} className="hamburger" onClick={() => setMobileMenuOpen(prevState => !prevState)} alt="" />
            </>
          </header>
          <nav className={`navbar navbar-mobile ${isMobileMenuOpen ? "navbar-mobile--open" : ""}`}>
            <div className={`nav-items`}>
              {options}
            </div>
          </nav>
        </>

      ) : (
        <header className="header">
          <div className="logo-container">
            <a href="/"><img src={logo} alt="Safer West Logo" className="logo" /></a>
          </div>
          <nav className="navbar">
            <div className={`nav-items`}>
              {options}
            </div>
          </nav>
        </header>
      )}
    </>
  )
};


export default Navbar;