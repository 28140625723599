import stPatricksBlackieOutreachLagmore from "../../assets/event/17-08-23-blackie/lagmore.jpg";
import stPatricksBlackieOutreachFalls from "../../assets/event/17-08-23-blackie/falls.jpg";
import relationshipBuilding from "../../assets/event/relationshipBuilding.jpg";
import oneStopShop from "../../assets/event/oneStopShop.jpg";
import gangAwareness from "../../assets/event/gangAwareness.png";
import infoAndExchange from "../../assets/event/infoAndExchange.jpg";
import fireworkSafety from "../../assets/event/fireworksSafety.jpg";
import drugsTraining from "../../assets/event/drugsTraining.jpg";
import outreachDisco from "../../assets/event/outreachDisco.jpeg";
import footballBlackie8thAugust from "../../assets/event/08-08-23-blackie/Image 3.jpg";
import footballBlackie11thJuly from "../../assets/event/11-07-23-blackie/SJS July 5.jpg";
import antiSpikingUACF from "../../assets/uacf/uacft-5.jpeg";
import domesticAbuseUACF from "../../assets/uacf/uacft-1.jpeg";
import domesticAbuse from "../../assets/event/domesticAbuse.jpg";
import seniorSafety from "../../assets/event/Senior Safety 3 - BRG.jpg";
import womensSafety from "../../assets/event/Footprints Women's Centre.jpg";
import reimagining from "../../assets/event/reimagining.jpeg";
import infoEvent from "../../assets/event/information-event.jpeg";

export const eventData = [
  // {
  //   "title": "Football Event",
  //   "type": "Sport",
  //   "description": "A funfilled football event hosted by blackie community center.",
  //   "tags": "Family Friendly",
  //   "date": {
  //     "day": "8",
  //     "month": "August",
  //     "year": "2023"
  //   },
  //   "image": eventDetailsImage,
  //   "dateRange": "",
  //   "overview": [
  //     ""
  //   ],
  //   "addedDetails": [
  //   ],
  //   "locationText": "Belvedere Amphitheatre Paddington NSW 2021 Australia",
  //   "eventDetailsImage": eventDetailsImage,
  //   "eventRanBy": "Blackie River Community Group",
  // },
  {
    "title": "Community Information",
    "type": "Outreach",
    "description": "Learn about local safety initiatives, support services, and ways you can contribute to creating a safer community for everyone.",
    "tags": "Exhibition",
    "date": {
      "day": "24",
      "month": "February",
      "year": "2024"
    },
    "image": infoEvent,
    "overview": [
      "This event provides valuable information on community safety resources and strategies to help individuals protect themselves and their loved ones.",
      "Learn about local safety initiatives, support services, and ways you can contribute to creating a safer community for everyone"
    ],
    "addedDetails": [
      "Information booths from support organizations specializing in domestic violence, senior safety, or other community concerns."
    ],
    "locationText": "Upper Andersonstown Community Forum",
    "locationPosition": {lat: 54.5757951, lng: -6.0033786},
    "eventDetailsImage": infoEvent,
    "eventRanBy": "Upper Andersonstown Community Forum",
  },
  {
    "title": "Domestic Abuse",
    "type": "Community info/safety",
    "description": "This event aims to raise awareness about domestic abuse and help prevent it.",
    "tags": "Exhibition",
    "date": {
      "day": "24",
      "month": "December",
      "year": "2023"
    },
    "image": domesticAbuse,
    "overview": [
      "This event aims to raise awareness about domestic abuse, provide information on how to identify it, offer resources to victims, and empower the community to take action.",
      "The event will emphasize both the importance of seeking help and the availability of support services, especially during the holiday season, when instances of domestic abuse can increase."
    ],
    "addedDetails": [
      "Definitions of different types of domestic abuse (physical, emotional, sexual, financial, etc.).",
      "Signs and red flags someone might be experiencing domestic abuse.",
      "Dispelling common myths about domestic abuse.",
      "Contact details for shelters and safe houses.",
      "How to support someone experiencing domestic abuse.",
      "Ways individuals can help prevent domestic abuse."
    ],
    "locationText": "Upper Andersonstown Community Forum",
    "locationPosition": {lat: 54.5757951, lng: -6.0033786},
    "eventDetailsImage": domesticAbuse,
    "eventRanBy": "Upper Andersonstown Community Forum",
  },
  {
    "title": "Young Women's Safety",
    "type": "Community info/safety",
    "description": "This event empowers young women with knowledge and resources to enhance their personal safety in various situations.",
    "tags": "Exhibition",
    "date": {
      "day": "23",
      "month": "December",  
      "year": "2023"
    },
    "image": womensSafety,
    "overview": [
      "This event aims to empower women with the knowledge and resources to stay safe in various situations.",
      "The event will cover topics like personal safety planning, recognizing and responding to risky situations."
    ],
    "addedDetails": [
      "Strategies for developing a safety plan for everyday activities (walking home alone, using public transportation, etc.).",
      "Staying safe online, including social media dangers and privacy settings.",
      "Bystander intervention techniques to help others in potentially risky situations.",
      "Legal resources including relevant laws concerning harassment, stalking, and assault (tailored to your region)."
    ],
    "locationText": "Upper Springfield Development Trust",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": womensSafety,
    "eventRanBy": "Upper Springfield Development Trust",
  },
  {
    "title": "Senior Safety",
    "type": "Community info/safety",
    "description": "This event focuses on addressing safety concerns unique to seniors and helps them protect themselves.",
    "tags": "Exhibition",
    "date": {
      "day": "22",
      "month": "December",
      "year": "2023"
    },
    "image": seniorSafety,
    "overview": [
      "This event focuses on safety concerns unique to seniors, helping them protect themselves from potential dangers.",
      "The event may cover topics like fall prevention, home security, and avoiding scams and financial exploitation." 
    ],
    "addedDetails": [
      "Fall prevention tips and exercises.",
      "Home safety assessments and recommendations.",
      "Identifying common scams targeting seniors (phone, online, in-person).",
      "Resources for reporting fraud and abuse, and legal assistance."
    ],
    "locationText": "",
    "eventDetailsImage": seniorSafety,
    "eventRanBy": "Falls Residents Association",
  },
  {
    "title": "Anti Spiking Campaign",
    "type": "Community info/safety",
    "description": "This event raises awareness about drink spiking, teaching how to stay safe and what to do if you suspect it has happened.",
    "tags": "Exhibition",
    "date": {
      "day": "17",
      "month": "December",
      "year": "2023"
    },
    "image": antiSpikingUACF,
    "overview": [
      "This event raises awareness about the dangers of drink spiking, providing information on how to stay safe and what to do if you suspect you or someone else has been a victim.",
      "The event might focus on ways to protect your drinks, recognizing signs of being drugged, and how to get help."
    ],
    "addedDetails": [
      "Strategies for protecting your drinks in social settings.",
      "Common substances used for spiking.",
      "Symptoms of being drugged and immediate steps to take.",
      "Contact information for local support lines and resources for seeking medical help."
    ],
    "locationText": "Upper Andersonstown Community Forum",
    "locationPosition": {lat: 54.5757951, lng: -6.0033786},
    "eventDetailsImage": antiSpikingUACF,
    "eventRanBy": "Upper Andersonstown Community Forum",
  },
  {
    "title": "Domestic Abuse UACF",
    "type": "Community info/safety",
    "description": "This event aims to raise awareness about domestic abuse and help prevent it.",
    "tags": "Exhibition",
    "date": {
      "day": "24",
      "month": "December",
      "year": "2023"
    },
    "image": domesticAbuseUACF,
    "overview": [
      "This event aims to raise awareness about domestic abuse, provide information on how to identify it, offer resources to victims, and empower the community to take action.",
      "The event will emphasize both the importance of seeking help and the availability of support services, especially during the holiday season, when instances of domestic abuse can increase."
    ],
    "addedDetails": [
      "Definitions of different types of domestic abuse (physical, emotional, sexual, financial, etc.).",
      "Signs and red flags someone might be experiencing domestic abuse.",
      "Dispelling common myths about domestic abuse.",
      "Contact details for shelters and safe houses.",
      "How to support someone experiencing domestic abuse.",
      "Ways individuals can help prevent domestic abuse."
    ],
    "locationText": "Upper Andersonstown Community Forum",
    "locationPosition": {lat: 54.5757951, lng: -6.0033786},
    "eventDetailsImage": domesticAbuseUACF,
    "eventRanBy": "Upper Andersonstown Community Forum",
  },
  {
    "title": "Information and Exchange Visit",
    "type": "Exhibitions",
    "description": "This event facilitates sharing ideas and information between different communities to learn best practices and build partnerships.",
    "tags": "Exhibition",
    "date": {
      "day": "29",
      "month": "November",
      "year": "2023"
    },
    "image": infoAndExchange,
    "overview": [
      "This event facilitates the exchange of ideas and information between different communities or organizations.",
      "Participants may learn about best practices, network with peers, and potentially establish partnerships."
    ],
    "addedDetails": [
      "General themes or areas of focus for the knowledge exchange.",
      "Types of communities or organizations involved.",
      "Potential discussion topics or presentations.",
      "Opportunities for networking and building collaborations."
    ],
    "locationText": "Upper Springfield Development Trust",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": infoAndExchange,
    "eventRanBy": "All",
  },
  {
    "title": "Fireworks Campaign",
    "type": "Community info/safety",
    "description": "This event promotes safe handling of fireworks and raises awareness about the dangers.",
    "tags": "Workshop",
    "date": {
      "day": "29",
      "month": "November",
      "year": "2023"
    },
    "image": fireworkSafety,
    "overview": [
      "This event raises awareness about the dangers of fireworks and promotes safe handling practices.",
      "The event might cover topics like firework regulations, potential injuries, and firework safety tips for both individuals and communities." 
    ],
    "addedDetails": [
      "Local firework regulations and restrictions.",
      "Safe distances for setting off fireworks.",
      "Firework safety tips for pets and people with sensitivities.",
      "Alternatives to fireworks for celebrations."
    ],
    "locationText": "Upper Andersonstown Community Forum",
    "locationPosition": {lat: 54.5757951, lng: -6.0033786},
    "eventDetailsImage": fireworkSafety,
    "eventRanBy": "Upper Andersonstown Community Forum",
  },
  {
    "title": "Drugs Training",
    "type": "Community info/safety",
    "description": "This event provides information and resources related to drug and alcohol awareness, offering support and potential training.",
    "tags": "Workshop",
    "date": {
      "day": "29",
      "month": "November",
      "year": "2023"
    },
    "image": drugsTraining,
    "overview": [
      "This event provides information and resources related to drug and alcohol awareness, offering support and potential training.",
      "The event might focus on the dangers of substance abuse, signs of addiction, and how to get help for yourself or loved ones. "
    ],
    "addedDetails": [
      "Identifying different types of substances and their effects.",
      "Signs of drug or alcohol addiction.", 
      "Local support services and resources for those struggling with addiction.",
      "Strategies for having conversations about substance abuse with loved ones."
    ],
    "locationText": "Upper Springfield Development Trust",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": drugsTraining,
    "eventRanBy": "All",
  },
  {
    "title": "Reimagining",
    "type": "Community info/safety",
    "description": "This event encourages the community to reimagine and revitalize aspects of their surroundings, focusing on safety.",
    "tags": "Exhibition",
    "date": {
      "day": "23",
      "month": "August",  
      "year": "2023"
    },
    "image": reimagining,
    "overview": [
      "This event encourages rethinking and reimagining aspects of the community, potentially focusing on safety, improvement, or development.",
      "The event will invite ideas for revitalizing spaces, community initiatives, or addressing specific issues. " 
    ],
    "addedDetails": [
      "Potential focus areas for 'reimagining' (e.g., public spaces, services, community engagement).",
      "Ways for individuals to participate and share their visions.",
      "Possible collaboration with urban planners, architects, or other experts.",
      "Goals for collecting input and turning ideas into action."
    ],
    "locationText": "Upper Andersonstown Community Forum",
    "locationPosition": {lat: 54.5757951, lng: -6.0033786},
    "eventDetailsImage": reimagining,
    "eventRanBy": "Upper Andersonstown Community Forum",
  },
  {
    "title": "Football",
    "type": "Outreach",
    "description": "This event offers a fun-filled football experience for kids hosted by the Blackie Community Center.",
    "tags": "Family Friendly",
    "date": {
      "day": "8",
      "month": "August",
      "year": "2023"
    },
    "image": footballBlackie8thAugust,
    "overview": [
      "Get ready to score some goals and have a blast at this awesome football event!",
      "Whether you're a football star or just love to play, there's something for everyone." 
    ],
    "addedDetails": [
      "Skill drills and fun games for all ages.",
      "Maybe even a friendly match or tournament.",
      "Chance to make new friends and show off your football moves.",
      "Bring your water bottle and your team spirit!" 
    ],
    "locationText": "Blackie River Community Group",
    "locationPosition": {lat: 54.5955175, lng: -5.9644012},
    "eventDetailsImage": footballBlackie8thAugust,
    "eventRanBy": "Blackie River Community Group",
  },
  {
    "title": "Disco",
    "type": "Outreach",
    "description": "This event is a cool disco party for kids to dance, have fun with friends, and show off their moves.",
    "tags": "Workshop",
    "date": {
      "day": "8",
      "month": "August",
      "year": "2023"
    },
    "image": outreachDisco,
    "overview":[
      "Get ready to groove at this disco party!",
      "Show off your best dance moves under the disco lights and have fun with your friends."
    ],
    "addedDetails": [
      "Awesome music to get you dancing.",
      "Fun games and activities.",
      "Maybe even a dance contest!",
      "Dress to impress (sparkles and glow sticks welcome!)" 
    ],
    "locationText": "Newhill",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": outreachDisco,
    "eventRanBy": "Upper Springfield Development Trust",
  },
  {
    "title": "Football - 11th July",
    "type": "Outreach",
    "description": "This event offers a fun-filled football experience for kids hosted by the Blackie Community Center.",
    "tags": "Family Friendly",
    "date": {
      "day": "11",
      "month": "July",
      "year": "2023"
    },
    "image": footballBlackie11thJuly,
    "overview": [
      "Get ready to score some goals and have a blast at this awesome football event!",
      "Whether you're a football star or just love to play, there's something for everyone." 
    ],
    "addedDetails": [
      "Skill drills and fun games for all ages.",
      "Maybe even a friendly match or tournament.",
      "Chance to make new friends and show off your football moves.",
      "Bring your water bottle and your team spirit!" 
    ],
    "locationText": "Blackie River Community Group",
    "locationPosition": {lat: 54.5955175, lng: -5.9644012},
    "eventDetailsImage": footballBlackie11thJuly,
    "eventRanBy": "Blackie River Community Group",
  },
  {
    "title": "Football - Lagmore",
    "type": "Outreach",
    "description": "This event offers a fun-filled football experience for kids.",
    "tags": "Family Friendly",
    "date": {
      "day": "17",
      "month": "March",
      "year": "2023"
    },
    "image": stPatricksBlackieOutreachLagmore,
    "overview": [
      "Get ready to score some goals and have a blast at this awesome football event!",
      "Whether you're a football star or just love to play, there's something for everyone." 
    ],
    "addedDetails": [
      "Skill drills and fun games for all ages.",
      "Maybe even a friendly match or tournament.",
      "Chance to make new friends and show off your football moves.",
      "Bring your water bottle and your team spirit!" 
    ],
    "locationText": "Lagmore & Mount Eagles",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": stPatricksBlackieOutreachLagmore,
    "eventRanBy": "",
  },
  {
    "title": "St Patricks Day - Football - Falls",
    "type": "Outreach",
    "description": "This event offers a fun-filled football experience for kids.",
    "tags": "Family Friendly",
    "date": {
      "day": "17",
      "month": "March",
      "year": "2023"
    },
    "image": stPatricksBlackieOutreachFalls,
    "overview": [
      "Get ready to score some goals and have a blast at this awesome football event!",
      "Whether you're a football star or just love to play, there's something for everyone." 
    ],
    "addedDetails": [
      "Skill drills and fun games for all ages.",
      "Maybe even a friendly match or tournament.",
      "Chance to make new friends and show off your football moves.",
      "Bring your water bottle and your team spirit!" 
    ],
    "locationText": "Falls Park",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": stPatricksBlackieOutreachFalls,
    "eventRanBy": "Upper Springfield Development Trust",
  },
  {
    "title": "Relationship Building - BMSSP",
    "type": "Community info/safety",
    "description": "This workshop focuses on strengthening relationships within the community, potentially between different groups or organizations.",
    "tags": "Workshop",
    "date": {
      "day": "17",
      "month": "March",
      "year": "2023"
    },
    "image": relationshipBuilding,
    "overview": [
      "This workshop focuses on strengthening relationships within the community, potentially between different groups or organizations. ",
      "Participants might learn communication skills, conflict resolution, and how to build trust and partnerships." 
    ],
    "addedDetails": [
      "Strategies for effective communication and active listening.",
      "Techniques for resolving disagreements constructively.",
      "Benefits of collaboration and how to create successful partnerships.",
      "Potential case studies or examples of strong community relationships. "
    ],
    "locationText": "Upper Springfield Development Trust",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": relationshipBuilding,
    "eventRanBy": "",
  },
  {
    "title": "One Stop Shop",
    "type": "Community info/safety",
    "description": "This event provides a central location for accessing various community services, information, and resources.",
    "tags": "Exhibitions",
    "date": {
      "day": "17",
      "month": "March",
      "year": "2023"
    },
    "image": oneStopShop,
    "overview": [
      "This event provides a central location for accessing various community services, information, and resources.",
      "Participants might find representatives from different organizations offering support or advice."
    ],
    "addedDetails": [
      "Types of services or organizations that might be present (e.g., housing support, employment advice, health information).",
      "Presentations or demonstrations related to the resources available.",
      "Opportunities for individuals to ask questions and get personalized assistance.",
      "Information on how to follow up with organizations after the event." 
    ],
    "locationText": "Upper Springfield Development Trust",
    "locationPosition": {lat: 54.5942368, lng: -5.9914651},
    "eventDetailsImage": oneStopShop,
    "eventRanBy": "Upper Springfield Development Trust",
  },
  {
    "title": "OCN",
    "type": "Community info/safety",
    "description": "This workshop educates participants about the dangers of gangs and their impact on communities.",
    "tags": "Workshop",
    "date": {
      "day": "17",
      "month": "March",
      "year": "2023"
    },
    "image": gangAwareness,
    "overview": [
      "This workshop aims to educate participants about the dangers of gangs and their impact on communities.",
      "It might cover topics like how gangs operate, warning signs, and strategies for protecting individuals and communities. "
    ],
    "addedDetails": [
      "Identifying recruitment tactics used by gangs.",
      "Understanding the risks associated with gang involvement.",
      "Resources for seeking help or reporting gang activity.",
      "Ways for communities to work together to prevent gang involvement. " 
    ],
    "locationText": "",
    "eventDetailsImage": gangAwareness,
    "eventRanBy": "Colin Community Safety",
  },
];