import { useState } from "react";
import EventCard from "../../components/event-card";
import Filters from "../../components/filters";
import "./styles.css";

const EventsList = ({ events }) => {
  const [listedEvents, setListedEvents] = useState({
    original: events,
    filtered: false,
  });
  return (
    <div className="eventsListContainer">
      <Filters listedEvents={listedEvents} setFilteredEvents={setListedEvents} />
      {listedEvents.filtered && listedEvents.filtered.map((event, index) => (
        <EventCard key={index} event={event} />
      ))}
      {!listedEvents.filtered && listedEvents.original.map((event, index) => (
        <EventCard key={index} event={event} />
      ))
      }
    </div>
  );
};

export default EventsList;