import { useNavigate } from "react-router";
import "./styles.css";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className="back-button">
      <span onClick={() => navigate(-1)}><i class="fa-solid fa-arrow-left"></i> Back</span>
    </div>
  );
}

export default BackButton;