import Button from "../button";
import "./styles.css";

const Hero = ({ image, title, subtitle, buttonText, alignment, isDisplayTitle, className, buttonUrl, textColour }) => {
  return (
    <section className={`${className ? className : ""} hero${alignment ? ` hero-${alignment}` : ""}`} style={{ backgroundImage: `${image}` }} >
      <div className={`hero-content ${textColour && `hero-content-${textColour}`}`}>
        {alignment === "center" &&
          <div>
            <h1 className={`title${isDisplayTitle ? " title-display" : ""}`}>{title}</h1>
            {subtitle &&
                <p className="subtitle">
                  {subtitle}
                </p>
            }
            <Button onClick={() => window.location.assign(buttonUrl)} buttonText={buttonText} />
          </div>
        }
      </div>
      {alignment !== "center" &&
          <div className="content-wrapper">
            <h1 className={`title${isDisplayTitle ? " title-display" : ""}`}>{title}</h1>
            <Button onClick={() => window.location.assign(buttonUrl)} buttonText={buttonText} />
          </div>
        }
    </section>
  );
};

export default Hero;