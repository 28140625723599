import OrgCard from "../../components/org-card";
import { orgData } from "../../orgData";
import gsycDotImage from "../../assets/links/gsycdot.jpg";
import gsycClassImage from "../../assets/links/gsycclass.jpg";
import "./styles.css";

const ContactUs = () => {
  return (
    <div className="contactUs">
      <h1>Our Partners</h1>
      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec leo magna. Proin accumsan tincidunt lobortis. In et lacus eu ligula efficitur fermentum ac id ligula. Maecenas dapibus felis a placerat condimentum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus id semper lectus. Suspendisse in augue at nulla dictum egestas sed nec odio. Fusce quis erat in dui blandit commodo eu sed mi. Aenean luctus sit amet massa eu tempor. Integer hendrerit nunc sed velit fringilla tincidunt. Nunc dignissim aliquet maximus. Duis leo nisi, placerat a risus non, suscipit consequat magna. In hac habitasse platea dictumst.</p> */}
      <div className="orgList">
        {orgData.map((org) => <OrgCard org={org} />)}
      </div>
      <h1>Useful Links</h1>
      <div className="orgList links">
        <a href="https://www.gsyc.uk/parents-dotguide" target="_blank" rel="noreferrer">
          <OrgCard org={{
            image: gsycDotImage,
            title: "Have You Connected The Dots? | Greater Shankill Youth Connects",
            description: "Our Parent's Dot Guide is an online toolkit developed by parents to help and support other Parents addressing the topic of Drugs with their Children. All information is relevant and specific to families and issues throughout Greater Shankill.",
            handleOnClick: () => { },
            link: "https://www.gsyc.uk/parents-dotguide"
          }} />
        </a>
        <a href="https://www.gsyc.uk/parents-dotguide" target="_blank" rel="noreferrer">
          <OrgCard org={{
            image: gsycClassImage,
            title: "CLASS | Greater Shankill Youth Connects",
            description: "CLASS is an online info point created for young people across Greater Shankill and beyond. It has lots of information about many different drugs and may help answer some of the questions you have.",
            handleOnClick: () => { },
            link: "https://www.gsyc.uk/class"
          }} />
        </a>
      </div>
    </div>
  );
}

export default ContactUs;