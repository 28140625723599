import Select from "react-select";
import "./styles.css";
import Button from "../button";
import BackButton from "../back-button";

const Filters = ({ listedEvents, setFilteredEvents }) => {
  const onFilterSelect = (key, selectedValue) => {
    setFilteredEvents({
      ...listedEvents,
      filtered: listedEvents.original.filter(event => event[key].includes(selectedValue) || (key === "eventRanBy" && selectedValue === "All"))
    })
  };
  return (
    <div className="filters">
      <div className="filters_wrapper">
        <BackButton />
        <Select options={[
          {
            value: "newest",
            label: "Order By: Newest",
          }
        ]}
          defaultValue={{
            value: "newest",
            label: "Order By: Newest",
          }} className="filter" />
        <Select options={[
          {
            value: "All",
            label: "Ran By: All",
          },
          {
            value: "Upper Springfield Development Trust",
            label: "Ran By: Upper Springfield Development Trust",
          },
          {
            value: "Blackie River Community Group",
            label: "Ran By: Blackie River Community Group",
          },
          {
            value: "Falls Residents Association",
            label: "Ran By: Falls Residents Association",
          },
          {
            value: "Upper Andersonstown Community Forum",
            label: "Ran By: Upper Andersonstown Community Forum",
          }
        ]}
          defaultValue={{
            value: "All",
            label: "Ran By: All",
          }} className="filter"
          onChange={(e) => onFilterSelect("eventRanBy", e.value)} />
        <Select options={[
          {
            value: "Outreach",
            label: "Event Type: Outreach",
          },
          {
            value: "Community info/safety",
            label: "Event Type: Community info/safety",
          },
          {
            value: "Addiction Support",
            label: "Event Type: Addiction Support",
          },
          {
            value: "Exhibitions",
            label: "Event Type: Exhibitions",
          },
          {
            value: "Annual Events",
            label: "Event Type: Annual Events",
          }
        ]}
          defaultValue={{
            value: "Exhibitions",
            label: "Event Type: Exhibitions",
          }} className="filter"
          onChange={(e) => onFilterSelect("type", e.value)} />
        <Button
          className="clear"
          buttonText="Reset"
          onClick={() => setFilteredEvents({
            ...listedEvents,
            filtered: false,
          })}
        />
      </div>
      <div>
        <span>{listedEvents.filtered ? listedEvents.filtered.length : listedEvents.original.length} events found</span>
      </div>
    </div>
  );
};

export default Filters;