import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

const GoogleMap = ({ position }) => {
  return (
    <APIProvider apiKey={'AIzaSyC4N_dWufWXr-9xGmXhzE-p6gq1LuwHAwA'}>
      <Map center={position} zoom={13} style={{width: '400px', height: '400px', maxWidth: '100%'}}>
        <Marker position={position} />
      </Map>
    </APIProvider>
  );
}

export default GoogleMap;