import { useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import dateIcon from "../../date.svg";
import EventCarousel from "../event-carousel";
import "./styles.css";
import GoogleMap from '../../components/map';
import BackButton from '../../components/back-button';

const EventDetails = ({ event }) => {

  const location = useLocation();
  
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <div className="eventDetails">
      <BackButton />
        <div className="eventDetailsHeader">
          <img src={event.eventDetailsImage} alt={event.title} />
          <div>
            <div><img src={dateIcon} alt="Date" className="dateIcon" />{event.date.day} {event.date.month} {event.date.year}</div>
          </div>
        </div>
        <div className="eventDetailsContent">
          <div>
            <h1>{event.title}</h1>
            <h2>Overview</h2>
            {event.overview && event.overview.map(item => (
              <p>{item}</p>
            ))}
            <h2>Additional Information</h2>
            {event.addedDetails && (
              <ul>
                {event.addedDetails.map(item => (
                  <li>{item}</li>
                ))}
              </ul>
            )}

          </div>
          <div className='location'>
            {(event.locationText || event.locationPosition) &&
              <>
                <h3>Location</h3>
                {event.locationText && <span>{event.locationText}</span>}
                {event.locationPosition &&
                  <div id="map">
                    <GoogleMap position={event.locationPosition} />
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
      <EventCarousel title="Upcoming Events" />
    </>
  );
};

export default EventDetails;