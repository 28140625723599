
import { useNavigate } from "react-router";
import "./styles.css";
import { getUrlFromOrgName } from "../../utilities/helpers";

const OrgCard = ({ index, org }) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`../${getUrlFromOrgName(org.title)}`);
  };

  return (
    <div key={index} className="org" onClick={() => org.handleOnClick ? org.handleOnClick : handleOnClick()}>
      <div className="orgHeader">
        <img src={org.image} alt={org.title} />
      </div>
      <div className="orgContent">
        <span>{org.type}</span>
        <h2>{org.title}</h2>
        <p>{org.description}</p>
        <span><a href={`mailto:${org.email}`}>{org.email}</a></span>
        {org.link && <a href={org.link}>{org.link}</a>}
      </div>
    </div>
  );
};

export default OrgCard;