import EventCard from "../../components/event-card";
import { eventData } from "./eventData";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./styles.css";

const EventCarousel = ({ title, filteredData }) => {
  const carouselData = filteredData ? filteredData : eventData;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1500, min: 1182 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1182, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1
    }
  };
  return (
    <div className="eventCarouselContainer">
      <div className="eventCarouselHeader">
        <h1>{title}</h1>
        <span onClick={() => window.location.assign("all-events")}>View All</span>
      </div>
      <Carousel
        responsive={responsive}
        autoPlay={false}
        removeArrowOnDeviceType={["mobile"]}
        draggable={false}
        showDots={true}
        shouldResetAutoplay={false}
        // transitionDuration={9999999}
        >
        {carouselData.map((event, index) => (
          <EventCard key={index} event={event} />
        ))
        }
      </Carousel>
    </div>
  );
};

export default EventCarousel;