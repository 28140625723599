import FallsResidentsAssociationImage from "./assets/fallsResAsso.jpeg";
import FallsResidentsAssociationImage2 from "./assets/fallsResAsso2.jpeg";
import BlackieCommunityGroup from "./assets/blackie/blackieCommunity.jpg";
import BlackieCommunityGroup2 from "./assets/blackie/blackieCommunity2.jpg";
import BlackieCommunityGroup4 from "./assets/blackie/blackieCommunity4.jpg";
import BlackieCommunityGroup5 from "./assets/blackie/blackieCommunity5.jpg";
import UACF1 from "./assets/uacf/uacft-1.jpeg";
import UACF2 from "./assets/uacf/uacft-2.jpeg";
import UACF3 from "./assets/uacf/uacft-3.jpeg";
import UACF4 from "./assets/uacf/uacft-4.jpeg";
import UACF5 from "./assets/uacf/uacft-5.jpeg";
import usdtImage from "./assets/usdt/usdt.jpeg";
import usdtImage2 from "./assets/usdt/usdt2.jpeg";
import usdtImage3 from "./assets/usdt/usdt3.jpeg";
import usdtImage4 from "./assets/usdt/usdt4.jpeg";

export const orgData = [
  {
    "title": "Upper Springfield Development Trust",
    "description": "Since 1993 USDT Has Been A Community Based Organisation In The Springfield Area: helping to develop many successful programmes to support the area's social, economic, physical and cultural regeneration.",
    "image": usdtImage,
    "email": "info@usdt.co.uk",
    "phone": "02890 236677",
    "website": "",
    info: [
      "Since 1993 USDT has been a community based organisation in the Springfield area: helping to develop many successful programmes to support the area's social, economic, physical and cultural regeneration. Developing strong, inclusive communities is a key element in achieving sustainable community development; but who wants to sign-up to a society if they are made to feel second class?",
      "A sustainable community requires not only economic development, but also provision of and access to education, health and community support services, amenities and leisure services and a good quality built environment. To achieve this outcome our projects work with a broad range of groups and individuals: young people with complex needs, those with a disability, dependants, the elderly, school children, unemployed, minority ethnic groups, the social economy et al.",
      "We develop programmes that tackle crime, poor skills, economic poverty, poor social infrastructure, discrimination and area disadvantage. We continually develop new ideas for action that supports good practice in key areas of social inclusion policy and practice. We ensure our programme successes are effectively monitored, evaluated and effectively co-ordinated and integrated. We always seek to engage, develop, improve and transform people and our community.",
      "VALUES",
      "The values at the core of the organisation's core are community development based, they are:",
      [
        "Equality",
        "Integrity",
        "Passion",
        "Excellence",
        "Leadership and accountability",
        "Needs led",
        "Quality",
        "Humility"
      ],
      "STRATEGIC AIMS",
      "USDT's overarching goal is to ensure that the principles of community development, environmental sustainability and ensuring value for money pervade all our work and development.",
      "SPECIFIC THEMES",
      [
        "Leadership",
        "Reputation for Excellence",
        "Financial Sustainability",
        "Development of Social Enterprises",
        "Social Impact"
      ]
    ],
    allContacts: [
      {
        name: "Donal McKinney",
        title: "Chief Executive",
        email: "donalmckinney@usdt.co.uk"
      },
      {
        name: "Micheal Donnelly",
        title: "Community Development Manager",
        email: "m.donnelly@usdt.co.uk"
      },
      {
        name: "Aisling Heath",
        title: "West Belfast Community Safety/Communities in Transition Worker",
        email: "a.heath@usdt.co.uk"
      },
      {
        name: "Dee Donnelly",
        title: "West Belfast Community Safety Worker",
        email: "d.donnelly@usdt.co.uk"
      },
      {
        name: "Mary McArdle",
        title: "West Belfast Strategic Community Safety Worker",
        email: "m.mcardle@usdt.co.uk"
      },
      {
        name: "Lauren McCann",
        title: "Upper Springfield Community Development Worker",
        email: "l.mccann@usdt.co.uk"
      },
      {
        name: "Cillian McGivern",
        title: "Upper Springfield Drug and Alcohol Family Support Worker",
        email: "c.mcgivern@usdt.co.uk"
      }
    ],
    images: [
      usdtImage2,
      usdtImage,
      usdtImage3,
      usdtImage4
    ]
  },
  {
    "title": "Falls Residents Association",
    "description": "Falls Residents Association is a grass-roots community organisation with a charitable status. We aim to empower local residents and groups from the Falls area, regardless of age, gender, race, political, religious or other opinions.",
    "image": FallsResidentsAssociationImage,
    "email": "fallsresidentsassociation@gmail.com",
    "phone": "02890313038",
    "website": "",
    info: [
      "Falls Residents Association is a grass-roots community organisation with a charitable status. We aim to empower local residents and groups from the Falls area, regardless of age, gender, race, political, religious or other opinions. Through engagement with statutory agencies and voluntary organisations we aim to enhance and advance the lives of local residents in the following area:",
      [
        "Education",
        "Health & Wellbeing",
        "Recreation & Leisure",
        "Social Welfare",
        "Cultural Diversity and Inclusion"
      ],
      "We create opportunities by facilitating and developing community programmes and activities that is inclusive and accessible to all. We provide opportunities to enhance the lives of all residents that embeds life-long learning and skills, enhances self-esteem and promotes self-worth. Our services include:",
      [
        "Housing Advice",
        "Welfare Rights",
        "Community Safety Clinics",
        "ICT for all ages",
        "Community programmes and events",
        "Youth & Older People's Services",
        "Family Support",
        "Community Development"
      ],
    ],
    allContacts: [
      {
        name: "Vincent McElkerney",
        title: "Community Development Worker",
        email: "fallsresidentsassociation@gmail.com",
        phone: "02890313038"
      },
      {
        name: "Lisa Lynn",
        title: "Community Safety Co-Ordinator",
        email: "fallsresidentsassociation@gmail.com",
        phone: "02890313038"
      },
    ],
    images: [
      FallsResidentsAssociationImage,
      FallsResidentsAssociationImage2
    ]
  },
  {
    "title": "Blackie River Community Group",
    "description": "Blackie River Community Group aims to empower the residents of the greater Beechmount area",
    "image": BlackieCommunityGroup,
    "email": "danny.murphy@blackieriver.ie",
    "phone": "02890319634",
    "website": "",
    info: ["Blackie River Community Group aims to empower the residents of the greater Beechmount area by facilitating and developing community development activity which enables them to celebrate their creativity and culture, by developing their skills to enhance their self-esteem, by assisting them in playing a full and equal role in society and by helping to secure the social and economic regeneration of the area. BRCG services includes Day-care &amp; Afterschool’s, Sure Start Programmes, Youth, Community Development and Education and Older Peoples events."],
    allContacts: [
      {
        name: "Danny Murphy",
        email: "danny.murphy@blackieriver.ie",
      },
      {
        name: "Mark Skillen",
        email: "markskillenstjames@blackieriver.ie"
      }
    ],
    images: [
      BlackieCommunityGroup2,
      BlackieCommunityGroup,
      BlackieCommunityGroup4,
      BlackieCommunityGroup5,
    ]
  },
  {
    "title": "Upper Andersonstown Community Forum",
    "description": "UACF is committed to enhancing community safety, well-being, social engagement, education, and family support in Greater Andersonstown through inclusive programs and collaborative efforts.",
    "image": UACF1,
    "email": "r.mclaughlin@uacf.ie",
    "phone": "02890622201",
    "website": "",
    info: [
      "Upper Andersonstown Community Forum (UACF) is a community organization dedicated to uplifting and supporting residents in the Greater Andersonstown area. With a focus on community safety and development, we strive to create a vibrant and secure environment for all members, irrespective of background or circumstance.",
      "Our mission is to empower individuals and groups within our community by fostering engagement with both statutory agencies and voluntary organizations. Through collaborative efforts, we aim to enhance the quality of life for residents across various domains.",
      [
        "Community Safety: We prioritize initiatives that promote safety and well-being, including drug support programs, addiction assistance, home safety workshops, and anti-scam campaigns.",
        "Health & Wellbeing: UACF is committed to improving the health and well-being of our community members. We offer resources and support through programs such as the Tullymore Beacon's Women's Group, which provides a network for local women facing personal challenges.",
        "Social Engagement: Our organization believes in the power of community engagement and social cohesion. We organize events, workshops, and activities that bring people together, fostering a sense of belonging and unity.",
        "Education and Training: We recognize the importance of lifelong learning and skill development. Through initiatives like ICT for all ages and training opportunities for those in need, we empower individuals to reach their full potential.",
        "Family Support: UACF provides essential support services for families, including housing advice, welfare rights assistance, and community development programs.",
      ],
      "At UACF, we are dedicated to creating inclusive and accessible programs that cater to the diverse needs of our community. By offering a range of services and opportunities, we strive to enrich lives, build resilience, and foster a stronger, more cohesive community for all.",
    ],
    allContacts: [
      {
        name: "Michael George",
        title: "Director",
        email: "m.george@uacf.ie",
      },
      {
        name: "Róisin McLaughlin",
        title: "Neighbourhood Renewal",
        email: "r.mclaughlin@uacf.ie"
      },
      {
        name: "Deirdre McVeigh",
        title: "Utopia Project",
        email: "d.mcveigh@uacf.ie"
      },
      {
        name: "Christine Polland",
        title: "Health & Well Being",
        email: "c.poland@uacf.ie"
      },
      {
        name: "Tina Murphy",
        title: "Welfare advice & Support",
        email: "tinamurphy604@gmail.com"
      },
    ],
    images: [
      UACF2,
      UACF3,
      UACF4,
      UACF5,
    ]
  },
];