import Hero from "../../components/hero";
import "./styles.css";
import CITLogo from "../../assets/Communities in Transition Logo.jpg";
import UACFLogo from "../../assets/UACF Logo.png";
import BlackieLogo from "../../assets/Blackie River Logo.png";
import FRALogo from "../../assets/FRA Logos.jpg";
import USDTLogo from "../../assets/USDT Logo.png";
import NIExecLogo from "../../assets/NI Executive high resultion logo.jpg";

const Footer = () => (
  <>
    <Hero className="footer-hero" image={`url('/assets/footerHero.png')`} title="Why not get involved?" subtitle="Together we can make build a better Belfast." buttonText="Explore Events" alignment="center" buttonUrl="/all-events" />
    <div className="footer-links">
      <div className="logo-list">
        <img src={CITLogo} alt="Communities in Transition" className="logo" />
        <img src={NIExecLogo} alt="Northern Ireland Executive" className="logo" />
        <img src={UACFLogo} alt="UACF" className="logo" />
        <img src={BlackieLogo} alt="Blackie River" className="logo" />
        <img src={FRALogo} alt="FRE" className="logo" />
        <img src={USDTLogo} alt="USDT" className="logo" />
      </div>
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
    <div className="quote">This West Belfast Community Safety app has been funded by The Executive Offices Communities in Transition Project</div>
  </>
);

export default Footer;