import { useLayoutEffect } from "react";
import EventCarousel from "../event-carousel";
import "./styles.css";
import { eventData } from "../event-carousel/eventData";
import BackButton from '../../components/back-button';
import { useLocation } from "react-router-dom";


const OrgDetails = ({ org }) => {
  const location = useLocation();
  
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <div className="orgDetails">
        <BackButton />
        <div className="orgDetailsHeader">
          {/* <div className="orgDetailsTitleContentWrapper">
            <div className="orgDetailsTitleContent">
              <h1>{org.title}</h1>
              <img src={org.image} alt={org.title} />
            </div>
          </div> */}
          <div className="orgDetailsOverlay" />
          <img src={org.image} alt={org.title} />
        </div>
        <div className="orgDetailsTitleContentWrapper">
          <div className="orgDetailsTitleContent">
            <h1>{org.title}</h1>
            <img src={org.images[1]} alt={org.title} />
          </div>
        </div>
        <div className="orgDetailsContent">
          <div>
            {/* <h1>{org.title}</h1> */}
            <h2>What We Do</h2>
            <>
              {org.info.map((item) => (
                Array.isArray(item) ? (
                  <ul>
                    {item.map(bullet => (
                      <li>{bullet}</li>
                    ))}
                  </ul>
                ) : <p>{item}</p>
              ))}
            </>
            {org.addedDetail && <h2>Additional Information</h2>}
            {org.addedDetails && (
              <ul>
                {org.addedDetails.map(item => (
                  <li>{item}</li>
                ))}
              </ul>
            )}

          </div>
          <div>
            <h2>Contact</h2>
            <div className="orgContacts">
              {org.allContacts.map((contact) => (
                <div className="orgContact">
                  {contact.name && <span className="contactName">{contact.name}</span>}
                  {contact.title && <span className="contactTitle">{contact.title}</span>}
                  {contact.email && <span className="contactEmail"><a href={`mailto:${contact.email}`}>{contact.email}</a></span>}
                  {contact.phone && <span className="contactPhone">{contact.phone}</span>}
                </div>
              ))}
            </div>
            {org.website &&
            <>
              <h2>Website</h2>
              <div className="orgContacts">
                  <div className="orgContact">
                  <span className="contactEmail"><a href={org.website} target="_blank" rel="noreferrer">{org.website}</a></span>
                  </div>
              </div>
              </>
            }
          </div>
        </div>
        {org.images && (
          <div className="orgImages">
            {org.images.map((image) => (
              <img src={image} alt={org.title} />
            ))}
          </div>
        )}
      </div>
      <EventCarousel title={`Upcoming Events for ${org.title}`} filteredData={eventData.filter(event => event.eventRanBy === org.title)} />
    </>
  );
};

export default OrgDetails;